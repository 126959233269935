module.exports = {
  locales: [
    'de',
    'en',
    'lv',
    'lt',
    'ro',
    'hu',
    'hr',
    'es',
    'et',
    'ru',
    'fr',
    'el',
    'sk',
  ],
  defaultLocale: 'en',
  pages: {
    '*': ['offer'],
    '/premium-payment-failed': ['payment-failed'],
    '/premium-thank-you': ['thank-you'],
  },
}
