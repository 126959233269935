/* eslint-disable no-console */
import { Translate } from 'next-translate'
import { useRouter } from 'next/router'
import { NextRouter } from 'next/router'
import { OFFER_PRICES } from './offerPrices'
import {
  ALLOWED_CATEGORIES,
  ALLOWED_GENDERS,
  ALLOWED_UNITS,
  EXPENSIVE_OFFER_CATEGORIES,
  MailigenPrice,
  Prices,
  REQUIRED_QUERY_PARAMS,
  ValidParams,
} from './types/offer'

export const getIncludeImagePath = (
  include: string,
  gender: string
): string => {
  switch (include) {
    case 'include_7':
      return `${gender}/${include}`
    case 'include_8': {
      return `${gender}/${include}`
    }
    default:
      return `${include}`
  }
}

export const useAppRequiredQueryParams = (): boolean => {
  const { query } = useRouter()
  const { gender, measurementUnit, userCategory } = useQueryParams()

  const requiredParams = Object.keys(query).filter((queryKey) =>
    REQUIRED_QUERY_PARAMS.includes(queryKey)
  )

  if (requiredParams.length < 7) {
    return false
  }

  const isValidParams = validateQueryParams(
    gender,
    measurementUnit,
    userCategory
  )

  return isValidParams
}

const validateQueryParams = (
  gender: string,
  measurementUnit: string,
  userCategory: string
) => {
  if (!ALLOWED_GENDERS.includes(gender)) {
    return false
  }
  if (!ALLOWED_UNITS.includes(measurementUnit)) {
    return false
  }
  if (!ALLOWED_CATEGORIES.includes(userCategory)) {
    return false
  }

  return true
}

export const useQueryParams = (): ValidParams => {
  const { query } = useRouter()

  const queryParams: ValidParams = {
    gender: query.gender as string,
    name: query.name as string,
    email: query.email as string,
    currency: query.currency as string,
    weight: query.weight as string,
    measurementUnit: query.unit as string,
    userCategory: query.category as string,
    cheap: parseInt(query.cheap as string),
  }

  return queryParams
}

export const mailigenCheapOfferNeeded = (
  router: NextRouter,
  cheap: number
): boolean => cheap && getOriginName(router) === 'mailigen=1'

export const useOfferPrices = (): Prices => {
  const router = useRouter()
  const { locale } = router
  const { currency, userCategory, cheap } = useQueryParams()

  if (mailigenCheapOfferNeeded(router, cheap)) {
    const mailigenCheapOfferPrices =
      cheap === MailigenPrice.cheap
        ? OFFER_PRICES.mailigenCheap
        : OFFER_PRICES.mailigenModerate

    const { discount_price, full_price, display_old_price }: Prices =
      mailigenCheapOfferPrices[locale][currency]
        ? mailigenCheapOfferPrices[locale][currency]
        : mailigenCheapOfferPrices[locale]['eur']

    return { discount_price, full_price, display_old_price }
  }

  if (EXPENSIVE_OFFER_CATEGORIES.includes(userCategory)) {
    const expensiveOfferPrices = OFFER_PRICES.expensive
    const { discount_price, full_price, display_old_price }: Prices =
      expensiveOfferPrices[locale][currency]
        ? expensiveOfferPrices[locale][currency]
        : expensiveOfferPrices[locale]['eur']

    return { discount_price, full_price, display_old_price }
  }

  const cheapOfferPrices = OFFER_PRICES.cheap
  const { discount_price, full_price, display_old_price }: Prices =
    cheapOfferPrices[locale][currency]
      ? cheapOfferPrices[locale][currency]
      : cheapOfferPrices[locale]['eur']

  return { discount_price, full_price, display_old_price }
}

export const returnToApplication = (router: NextRouter): void => {
  const {
    query: { email, pipe: registrationFlow },
  } = router
  const domainName = getDomainName(router)

  if (registrationFlow) {
    router.replace(`https://app.${domainName}/registration?email=${email}`)
    return
  }

  router.replace(`https://app.${domainName}/?email=${email}`)
  return
}

export const getThankyouReturnButtonText = (
  router: NextRouter,
  t: Translate
): string =>
  t(router.query.pipe ? 'button_return_registration' : 'button_return')

export const getOriginName = (router: NextRouter): string => {
  const {
    query: { mailigen, banner, pipe },
  } = router

  if (mailigen) {
    return 'mailigen=1'
  }
  if (banner) {
    return 'banner=1'
  }
  if (pipe) {
    return 'pipe=1'
  }

  return 'none'
}

export const getDomainName = (router: NextRouter): string => {
  const {
    query: { domain },
  } = router

  return (domain as string) || 'stockholmdiet.com'
}
