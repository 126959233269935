import { OfferPrices } from './types/offer'

export const OFFER_PRICES: OfferPrices = {
  cheap: {
    de: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    en: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      usd: {
        discount_price: '19.95$',
        full_price: '49.95$',
        display_old_price: 'USD 50.00',
      },
      gbp: {
        discount_price: '19.95£',
        full_price: '49.95£',
        display_old_price: 'GBP 50.00',
      },
    },
    lv: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    lt: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ro: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      ron: {
        discount_price: '99.90 Lei',
        full_price: '249.90 Lei',
        display_old_price: 'RON 250.00',
      },
    },
    hr: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    hu: {
      huf: {
        discount_price: '9999 Ft',
        full_price: '14 999 Ft',
        display_old_price: 'HUF 15 000',
      },
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    es: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    et: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ru: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    fr: {
      eur: {
        discount_price: '19.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    el: {
      eur: {
        discount_price: '14.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
    sk: {
      eur: {
        discount_price: '14.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
  },
  expensive: {
    de: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    en: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
      usd: {
        discount_price: '39.95$',
        full_price: '99.95$',
        display_old_price: 'USD 100.00',
      },
      gbp: {
        discount_price: '39.95£',
        full_price: '99.95£',
        display_old_price: 'GBP 100.00',
      },
    },
    lv: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    lt: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    ro: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
      ron: {
        discount_price: '199.90 Lei',
        full_price: '489.90 Lei',
        display_old_price: 'RON 490.00',
      },
    },
    hr: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    hu: {
      huf: {
        discount_price: '14 999 Ft',
        full_price: '29 999 Ft',
        display_old_price: 'HUF 30 000',
      },
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    es: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    et: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    ru: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    fr: {
      eur: {
        discount_price: '39.95€',
        full_price: '99.95€',
        display_old_price: 'EUR 100.00',
      },
    },
    el: {
      eur: {
        discount_price: '29.95€',
        full_price: '79.95€',
        display_old_price: 'EUR 80.00',
      },
    },
    sk: {
      eur: {
        discount_price: '29.95€',
        full_price: '79.95€',
        display_old_price: 'EUR 80.00',
      },
    },
  },
  mailigenCheap: {
    de: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    en: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      usd: {
        discount_price: '9.95$',
        full_price: '49.95$',
        display_old_price: 'USD 50.00',
      },
      gbp: {
        discount_price: '9.95£',
        full_price: '49.95£',
        display_old_price: 'GBP 50.00',
      },
    },
    lv: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    lt: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ro: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      ron: {
        discount_price: '40 Lei',
        full_price: '249.90 Lei',
        display_old_price: 'RON 250.00',
      },
    },
    hr: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    hu: {
      huf: {
        discount_price: '2999 Ft',
        full_price: '14 999 Ft',
        display_old_price: 'HUF 15 000',
      },
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    es: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    et: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ru: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    fr: {
      eur: {
        discount_price: '9.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    el: {
      eur: {
        discount_price: '9.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
    sk: {
      eur: {
        discount_price: '9.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
  },
  mailigenModerate: {
    de: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    en: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      usd: {
        discount_price: '12.95$',
        full_price: '49.95$',
        display_old_price: 'USD 50.00',
      },
      gbp: {
        discount_price: '12.95£',
        full_price: '49.95£',
        display_old_price: 'GBP 50.00',
      },
    },
    lv: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    lt: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ro: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
      ron: {
        discount_price: '59 Lei',
        full_price: '249.90 Lei',
        display_old_price: 'RON 250.00',
      },
    },
    hr: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    hu: {
      huf: {
        discount_price: '3999 Ft',
        full_price: '14 999 Ft',
        display_old_price: 'HUF 15 000',
      },
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    es: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    et: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    ru: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    fr: {
      eur: {
        discount_price: '12.95€',
        full_price: '49.95€',
        display_old_price: 'EUR 50.00',
      },
    },
    el: {
      eur: {
        discount_price: '12.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
    sk: {
      eur: {
        discount_price: '12.95€',
        full_price: '39.95€',
        display_old_price: 'EUR 40.00',
      },
    },
  },
}
