import { ReactNode, useEffect } from 'react'
import { useRouter } from 'next/router'
import * as gtm from '../lib/gtm'

type Props = {
  children: ReactNode
}

const GoogleTagManager: React.FC<Props> = ({ children }): any => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', gtm.pageview)

    return () => {
      router.events.off('routeChangeComplete', gtm.pageview)
    }
  }, [router.events])

  return children
}

export default GoogleTagManager
