
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import GoogleTagManager from '../components/GoogleTagManager'
import EnsureRequiredParams from '../components/EnsureRequiredParams'
import Layout from '../components/Layout'
import '../styles/style.scss'

type Props = {
  Component: React.FC & { Layout: React.FC }
  pageProps: any
}

const App: React.FC<Props> = ({ Component, pageProps }) => {
  return (
    <GoogleTagManager>
      <EnsureRequiredParams>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </EnsureRequiredParams>
    </GoogleTagManager>
  )
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  