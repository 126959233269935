import { EVENT_CATEGORY, EVENT_TRIGGER } from '../utils/gtmHelper'

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

declare global {
  interface Window {
    dataLayer: any
  }
}

export const pageview = (url: string): void => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  })
}

export const triggerEvent = (action: string, label: string): void => {
  window.dataLayer.push({
    event: EVENT_TRIGGER,
    eventCategory: EVENT_CATEGORY,
    eventAction: action,
    eventLabel: label,
  })
}

export type PurchaseData = {
  stripePaymentId: string
  priceWithCurrency: string
  priceInEur: number
  stripePriceId: string
}

export const triggerPurchaseEvent = (purchaseData: PurchaseData): void => {
  const { stripePaymentId, priceWithCurrency, priceInEur, stripePriceId } =
    purchaseData

  window.dataLayer.push({
    event: 'payment',
    transactionId: stripePaymentId,
    transactionAffiliation: 'premium.stockholmdiet.com',
    transactionTotal: priceInEur,
    transactionProducts: [
      {
        sku: `Premium Stripe Product ID ${stripePriceId}`,
        name: 'Premium',
        category: `${priceWithCurrency} | Premium offer`,
        price: priceInEur,
        quantity: 1,
      },
    ],
  })
}
