import { NextRouter } from 'next/router'

type LabelGroup = {
  [key: string]: Label
}

type Label = {
  registration: string
  mailigen: string
  offer: string
}

export const EVENT_TRIGGER = 'premium_button_click'
export const EVENT_CATEGORY = 'SDv6 Premium offer'

export enum Events {
  offerAccepted = 'premium_offer_accepted',
  offerDeclined = 'premium_offer_declined',
  retryAccept = 'premium_failed_payment_retry_button',
  retryDecline = 'premium_failed_payment_back_profile',
}

const LABELS: LabelGroup = {
  accept: {
    registration: 'registration_offer_accept',
    mailigen: 'email_offer_accept',
    offer: 'banner_offer_accept',
  },
  decline: {
    registration: 'registration_offer_decline',
    mailigen: 'email_offer_decline',
    offer: 'banner_offer_decline',
  },
}

const getLabel = (router: NextRouter, action: 'accept' | 'decline'): string => {
  const {
    query: { pipe: registrationFlow, banner: offerBanner },
  } = router

  if (registrationFlow) {
    return LABELS[action].registration
  }
  if (offerBanner) {
    return LABELS[action].offer
  }

  return LABELS[action].mailigen
}

export const getGtmEventLabel = (
  eventType: string,
  router: NextRouter
): string => {
  if (
    eventType.includes('offer_accepted') ||
    eventType.includes('retry_button')
  ) {
    return getLabel(router, 'accept')
  }

  return getLabel(router, 'decline')
}
