export enum PremiumOfferPerson {
  person1 = 'person_1',
  person2 = 'person_2',
  person3 = 'person_3',
}

export enum PremiumOfferTitle {
  title1 = 'title_1',
  title2 = 'title_2',
  title3 = 'title_3',
}

export enum PremiumOfferPrice {
  price1 = 'price_1',
  price2 = 'price_2',
  price3 = 'price_3',
}

export enum MailigenPrice {
  cheap = 1,
  moderate = 2,
}

export type ValidParams = {
  gender: string
  name: string
  email: string
  currency: string
  weight: string
  measurementUnit: string
  userCategory: string
  cheap?: number
}

export type OfferPrices = {
  cheap: OfferPricesLocale
  expensive: OfferPricesLocale
  mailigenCheap: OfferPricesLocale
  mailigenModerate: OfferPricesLocale
}

export type OfferPricesLocale = {
  [key: string]: OfferLocaleCurrencies
}

export type OfferLocaleCurrencies = {
  [key: string]: Prices
}

export type Prices = {
  discount_price: string
  full_price: string
  display_old_price: string
}

export const REQUIRED_QUERY_PARAMS = [
  'gender',
  'name',
  'email',
  'currency',
  'weight',
  'unit',
  'category',
]

export const EXPENSIVE_OFFER_CATEGORIES = ['6', '7', '8', '9', '10']

export const ALLOWED_GENDERS = ['male', 'female']
export const ALLOWED_UNITS = ['kg', 'lbs']
export const ALLOWED_CATEGORIES = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
]
