import Head from 'next/head'

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Head>
        <title>StockholmDiet</title>
        <meta
          name="description"
          content="Healthy and personalized weight loss plan. StockholmDiet.com weight loss plan is based entirely on tasty and healthy food and does not involve physical training, or starving or use of any food supplements, pills or medication."
        />
        <meta
          name="keywords"
          content="Slimming programme, Weight loss plan, Healthy slimming plan, healthy weight loss method, an individual programme, Supported by doctors and nutritionists, How to lose weight healthy and easy"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1f66cf" />
        <meta name="msapplication-TileColor" content="#1f66cf" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div className="wrapper">{children}</div>
    </>
  )
}

export default Layout
