import { StripePrices } from './types/stripe'

export const PROD_STRIPE_PRICES: StripePrices = {
  cheap: {
    en: {
      eur: {
        priceId: 'price_1IxrG6GUWW5PzyCJ5mDcjlcU',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJL8lNSHn3',
        coupounId: 'IZgvoypm',
      },
      usd: {
        priceId: 'price_1IxrG6GUWW5PzyCJFE0xGNoq',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJGcDbCMoI',
        coupounId: 'AXtAnVdv',
      },
      gbp: {
        priceId: 'price_1IxrG6GUWW5PzyCJ4Fkk0QxV',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJt5KuzNmQ',
        coupounId: 'p0Sla6tt',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1IxrPgGUWW5PzyCJB8rqKYR6',
        priceIdLastchance: 'price_1IxrPgGUWW5PzyCJ3h4alFXC',
        coupounId: 'InZowLCO',
      },
      ron: {
        priceId: 'price_1IxrPgGUWW5PzyCJ7FFTEQmY',
        priceIdLastchance: 'price_1IxrPgGUWW5PzyCJoO3MlECp',
        coupounId: 'iULS5j4v',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1IxsBwGUWW5PzyCJjTB56oHb',
        priceIdLastchance: 'price_1IxsBwGUWW5PzyCJ2ij3M3Qp',
        coupounId: 'p9WP54P8',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1IxsROGUWW5PzyCJ3XwfmwOW',
        priceIdLastchance: 'price_1IxsROGUWW5PzyCJ2ciAM2oX',
        coupounId: 'HQz3SzVb',
      },
    },
    et: {
      eur: {
        priceId: 'price_1IxuL3GUWW5PzyCJitTCLUYt',
        priceIdLastchance: 'price_1IxuL3GUWW5PzyCJENsm2ROg',
        coupounId: 'tksOgYq3',
      },
    },
    de: {
      eur: {
        priceId: 'price_1IxuTIGUWW5PzyCJPMPFbvE6',
        priceIdLastchance: 'price_1IxuTIGUWW5PzyCJmvm4WJpQ',
        coupounId: 'pVu9lNu2',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1IxuWRGUWW5PzyCJ2SlrJHJj',
        priceIdLastchance: 'price_1IxuWRGUWW5PzyCJQWsPf9vN',
        coupounId: 'NIu0tcHq',
      },
    },
    es: {
      eur: {
        priceId: 'price_1IxuaWGUWW5PzyCJO9SvQh0s',
        priceIdLastchance: 'price_1IxuaWGUWW5PzyCJ35MatLWI',
        coupounId: 'cXXypEBw',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1IxudoGUWW5PzyCJeri9z2FZ',
        priceIdLastchance: 'price_1IxudoGUWW5PzyCJdh7wUvGb',
        coupounId: 'zcwGhih3',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1IxugoGUWW5PzyCJNq7RlVhW',
        priceIdLastchance: 'price_1IxugoGUWW5PzyCJaq2iax9f',
        coupounId: 'TwiV2STI',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1IxulUGUWW5PzyCJkZ23wbf8',
        priceIdLastchance: 'price_1IxulUGUWW5PzyCJutskrcZr',
        coupounId: 'uzyEbYZa',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1IxupMGUWW5PzyCJnOcTVocF',
        priceIdLastchance: 'price_1IxupMGUWW5PzyCJ3htHT1Op',
        coupounId: 'G6QB6Icc',
      },
      eur: {
        priceId: 'price_1IyaOHGUWW5PzyCJfzOK3343',
        priceIdLastchance: 'price_1IyaOIGUWW5PzyCJMml9Oh5z',
        coupounId: 'VMB8rgOK',
      },
    },
    el: {
      eur: {
        priceId: 'price_1Ixuw1GUWW5PzyCJGHZt4QeF',
        priceIdLastchance: 'price_1Ixuw1GUWW5PzyCJNzMsNaRL',
        coupounId: 'IbRIwJKo',
      },
    },
  },
  expensive: {
    en: {
      eur: {
        priceId: 'price_1IxrG6GUWW5PzyCJjT32CCaS',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJSr6hMp4g',
        coupounId: '1ip1Dxao',
      },
      usd: {
        priceId: 'price_1IxrG6GUWW5PzyCJiHL7GFmD',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJku2xHvqr',
        coupounId: 'G5soqppg',
      },
      gbp: {
        priceId: 'price_1IxrG6GUWW5PzyCJ9pzm4XpJ',
        priceIdLastchance: 'price_1IxrG6GUWW5PzyCJddBe5oVl',
        coupounId: 'PAghbNRL',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1IxrPgGUWW5PzyCJNTetoW84',
        priceIdLastchance: 'price_1IxrPgGUWW5PzyCJ7njk3Ceo',
        coupounId: 'AaSefM7M',
      },
      ron: {
        priceId: 'price_1IxrPgGUWW5PzyCJhFk3iMHs',
        priceIdLastchance: 'price_1IxrPgGUWW5PzyCJQKXtUykr',
        coupounId: 'goqABz9f',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1IxsBwGUWW5PzyCJUHVh59cT',
        priceIdLastchance: 'price_1IxsBwGUWW5PzyCJrbtcFedu',
        coupounId: 'FpsST0nu',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1IxsROGUWW5PzyCJxASEO2P0',
        priceIdLastchance: 'price_1IxsROGUWW5PzyCJy1gaYPEh',
        coupounId: 'w9yuPzGl',
      },
    },
    et: {
      eur: {
        priceId: 'price_1IxuL3GUWW5PzyCJWzozTEpE',
        priceIdLastchance: 'price_1IxuL3GUWW5PzyCJwKH0sS8r',
        coupounId: 'NiHLquoC',
      },
    },
    de: {
      eur: {
        priceId: 'price_1IxuTIGUWW5PzyCJDlCXFtFl',
        priceIdLastchance: 'price_1IxuTIGUWW5PzyCJi6Y9dq5C',
        coupounId: 'uozWalhF',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1IxuWRGUWW5PzyCJyUkHXX0Y',
        priceIdLastchance: 'price_1IxuWRGUWW5PzyCJN5Wqtbdi',
        coupounId: 'K4qQjXB1',
      },
    },
    es: {
      eur: {
        priceId: 'price_1IxuaWGUWW5PzyCJEDXJPoFW',
        priceIdLastchance: 'price_1IxuaWGUWW5PzyCJJcOwRJH8',
        coupounId: 'ANm9i2ZI',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1IxudoGUWW5PzyCJWp0fVLzE',
        priceIdLastchance: 'price_1IxudoGUWW5PzyCJrLmEhUok',
        coupounId: 'ayWjGwjd',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1IxugoGUWW5PzyCJwWWtVIIz',
        priceIdLastchance: 'price_1IxugoGUWW5PzyCJOxO69fki',
        coupounId: 'kaQuUnHW',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1IxulUGUWW5PzyCJzjihIwA5',
        priceIdLastchance: 'price_1IxulUGUWW5PzyCJB9Gt8di5',
        coupounId: 'tJQnW9kd',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1IxupMGUWW5PzyCJspQVUMeC',
        priceIdLastchance: 'price_1IxupMGUWW5PzyCJVS8DT8jN',
        coupounId: 'RfuxI5Ys',
      },
      eur: {
        priceId: 'price_1IyaOIGUWW5PzyCJFc4lbfYt',
        priceIdLastchance: 'price_1IyaOIGUWW5PzyCJOj4zfftD',
        coupounId: 'qUKSCznZ',
      },
    },
    el: {
      eur: {
        priceId: 'price_1Ixuw1GUWW5PzyCJAgOl5ggQ',
        priceIdLastchance: 'price_1Ixuw1GUWW5PzyCJ4EMLhJdL',
        coupounId: 'GQVt6Gkx',
      },
    },
  },
  mailigenCheap: {
    en: {
      eur: {
        priceId: 'price_1J9omWGUWW5PzyCJM3YGq6cE',
        priceIdLastchance: 'price_1J9onKGUWW5PzyCJHrUoRunV',
        coupounId: 'qY5SniQj',
      },
      usd: {
        priceId: 'price_1J9ontGUWW5PzyCJNBU8lbQR',
        priceIdLastchance: 'price_1J9oo5GUWW5PzyCJuLI505kf',
        coupounId: 'GAN0gnp0',
      },
      gbp: {
        priceId: 'price_1J9ooPGUWW5PzyCJRMJCksk2',
        priceIdLastchance: 'price_1J9oocGUWW5PzyCJsVpdItTp',
        coupounId: 'ardCFefn',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1J9owAGUWW5PzyCJCPjWaKUw',
        priceIdLastchance: 'price_1J9owQGUWW5PzyCJOfTVdn5T',
        coupounId: 'vElQKgRU',
      },
      ron: {
        priceId: 'price_1J9oydGUWW5PzyCJWOVlI8q5',
        priceIdLastchance: 'price_1J9oz8GUWW5PzyCJIMDMClEO',
        coupounId: 'AzRP7Qhx',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1J9p1jGUWW5PzyCJFNGN9Iri',
        priceIdLastchance: 'price_1J9p1uGUWW5PzyCJZyXXeR9D',
        coupounId: 'OZ1aPXX8',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1J9pLEGUWW5PzyCJn3u7WIB7',
        priceIdLastchance: 'price_1J9pLQGUWW5PzyCJkUeY3mrj',
        coupounId: 'Dc0WTN7w',
      },
    },
    et: {
      eur: {
        priceId: 'price_1J9pMvGUWW5PzyCJITH9b39B',
        priceIdLastchance: 'price_1J9pN2GUWW5PzyCJUnf6sm5j',
        coupounId: 'Xcj0b2co',
      },
    },
    de: {
      eur: {
        priceId: 'price_1J9pNqGUWW5PzyCJutfZGg2O',
        priceIdLastchance: 'price_1J9pO0GUWW5PzyCJxgRVDe3W',
        coupounId: 'ZgJMEja7',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1J9pPjGUWW5PzyCJtNuD5lm5',
        priceIdLastchance: 'price_1J9pPtGUWW5PzyCJ2pkMXxyZ',
        coupounId: 'bmvzPfWY',
      },
    },
    es: {
      eur: {
        priceId: 'price_1J9pR6GUWW5PzyCJtQdC6Wsc',
        priceIdLastchance: 'price_1J9pRGGUWW5PzyCJ57GnGd21',
        coupounId: 'pwbu2YsN',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1J9pTxGUWW5PzyCJNxam4nY1',
        priceIdLastchance: 'price_1J9pU6GUWW5PzyCJfhNyK8vv',
        coupounId: 'aXEqP0KU',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1J9pViGUWW5PzyCJFEYJC1g6',
        priceIdLastchance: 'price_1J9pVtGUWW5PzyCJtjTwFTVi',
        coupounId: 'Mo6bfajJ',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1J9qwfGUWW5PzyCJJPR6sQ1Q',
        priceIdLastchance: 'price_1J9qwqGUWW5PzyCJofxjGNAb',
        coupounId: 'wbQTCKG6',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1J9qzaGUWW5PzyCJo7C0HHiE',
        priceIdLastchance: 'price_1J9qzqGUWW5PzyCJgZlKTvvM',
        coupounId: 'AlQgYiao',
      },
      eur: {
        priceId: 'price_1J9r0gGUWW5PzyCJqquMondE',
        priceIdLastchance: 'price_1J9r0lGUWW5PzyCJ5l9ZC1VX',
        coupounId: 'wW68Irp8',
      },
    },
    el: {
      eur: {
        priceId: 'price_1J9r2bGUWW5PzyCJj7jRYoiZ',
        priceIdLastchance: 'price_1J9r2nGUWW5PzyCJhKJ2v5L0',
        coupounId: 'V3uOX355',
      },
    },
  },
  mailigenModerate: {
    en: {
      eur: {
        priceId: 'price_1JFFmFGUWW5PzyCJIcNSF9bt',
        priceIdLastchance: 'price_1JFFmVGUWW5PzyCJGXqWCXXl',
        coupounId: 'rtIySyja',
      },
      usd: {
        priceId: 'price_1JFFnfGUWW5PzyCJoQyjz7XN',
        priceIdLastchance: 'price_1JFFnsGUWW5PzyCJmNx0E2jO',
        coupounId: 'rIg3d8xw',
      },
      gbp: {
        priceId: 'price_1JFFo9GUWW5PzyCJCiVl01vA',
        priceIdLastchance: 'price_1JFFoKGUWW5PzyCJ4I9jrlxl',
        coupounId: 'bOWXvti7',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1JFFrdGUWW5PzyCJQPkDnZxO',
        priceIdLastchance: 'price_1JFGbkGUWW5PzyCJiC0fOZZi',
        coupounId: 'HXtE4wiq',
      },
      ron: {
        priceId: 'price_1JFFsdGUWW5PzyCJ6MODteGI',
        priceIdLastchance: 'price_1JFFswGUWW5PzyCJFKGtfz0w',
        coupounId: '9lkQGapP',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1JFFwUGUWW5PzyCJg3oOnkSe',
        priceIdLastchance: 'price_1JFFwcGUWW5PzyCJII7Jwc9o',
        coupounId: 'wbnWEC6G',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1JFFxmGUWW5PzyCJnHySiDLr',
        priceIdLastchance: 'price_1JFFxzGUWW5PzyCJ6PPPoaPc',
        coupounId: '7sc1Elh8',
      },
    },
    et: {
      eur: {
        priceId: 'price_1JFFztGUWW5PzyCJ2BF4XZHi',
        priceIdLastchance: 'price_1JFG04GUWW5PzyCJXzeaaWbi',
        coupounId: 'TzKEOulH',
      },
    },
    de: {
      eur: {
        priceId: 'price_1JFG1gGUWW5PzyCJkyu1Rmpt',
        priceIdLastchance: 'price_1JFG1qGUWW5PzyCJ5vDOdxgb',
        coupounId: 'NucuMAsh',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1JFG3DGUWW5PzyCJI3pg9Pp8',
        priceIdLastchance: 'price_1JFG3RGUWW5PzyCJZIsF1sSt',
        coupounId: 'dfjTczR4',
      },
    },
    es: {
      eur: {
        priceId: 'price_1JFG5SGUWW5PzyCJBwcTqS63',
        priceIdLastchance: 'price_1JFG5eGUWW5PzyCJcQmq9DBz',
        coupounId: '2K0SyCLW',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1JFG7LGUWW5PzyCJWpFjLZsc',
        priceIdLastchance: 'price_1JFG7ZGUWW5PzyCJbrSOzGtY',
        coupounId: 'RpBtGPaV',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1JFG99GUWW5PzyCJrythVvnQ',
        priceIdLastchance: 'price_1JFG9LGUWW5PzyCJxdVoooIB',
        coupounId: 'tqhu3a0I',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1JFGB6GUWW5PzyCJJEgInxWS',
        priceIdLastchance: 'price_1JFGBJGUWW5PzyCJXXVV5IW6',
        coupounId: 'HLlisP6Y',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1JFGDFGUWW5PzyCJ1Wgk3eWc',
        priceIdLastchance: 'price_1JFGDgGUWW5PzyCJFvJCluFk',
        coupounId: 'kwViu06S',
      },
      eur: {
        priceId: 'price_1JFGE3GUWW5PzyCJdueXGlZB',
        priceIdLastchance: 'price_1JFGEMGUWW5PzyCJGBeIUKMx',
        coupounId: 'Qs2cMVKC',
      },
    },
    el: {
      eur: {
        priceId: 'price_1JFGH9GUWW5PzyCJyXGsREBE',
        priceIdLastchance: 'price_1JFGHNGUWW5PzyCJLg5wz4Ol',
        coupounId: 'mY6WaPFb',
      },
    },
  },
}

export const DEV_STRIPE_PRICES: StripePrices = {
  cheap: {
    en: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
      usd: {
        priceId: 'price_1IxnEDGUWW5PzyCJZgxezmkw',
        priceIdLastchance: 'price_1IxnDlGUWW5PzyCJVOoZgI2w',
        coupounId: 'IprlZo1S',
      },
      gbp: {
        priceId: 'price_1JA8kPGUWW5PzyCJMYHsqm4Q',
        priceIdLastchance: 'price_1JA8kDGUWW5PzyCJJedZqIQF',
        coupounId: 'NOIfaMLc',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
      ron: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    et: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    de: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    es: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
    el: {
      eur: {
        priceId: 'price_1Ixn92GUWW5PzyCJqxzJSyOz',
        priceIdLastchance: 'price_1Ixn7yGUWW5PzyCJ3azXpUKH',
        coupounId: '7cqWKSGx',
      },
    },
  },
  expensive: {
    en: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
      usd: {
        priceId: 'price_1IxnFBGUWW5PzyCJoCivL097',
        priceIdLastchance: 'price_1IxnEkGUWW5PzyCJUqeareF3',
        coupounId: 'cZ03zDKD',
      },
      gbp: {
        priceId: 'price_1JA8npGUWW5PzyCJfh3E54Nc',
        priceIdLastchance: 'price_1JA8nZGUWW5PzyCJdCeDcOP3',
        coupounId: 'Qpzt4EfO',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
      ron: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    et: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    de: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    es: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
    el: {
      eur: {
        priceId: 'price_1IxnD5GUWW5PzyCJVh5toTq0',
        priceIdLastchance: 'price_1IxnCLGUWW5PzyCJm0u2b96Q',
        coupounId: 'ZFnf50Tu',
      },
    },
  },
  mailigenCheap: {
    en: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
      usd: {
        priceId: 'price_1JA9snGUWW5PzyCJxeBsq8rC',
        priceIdLastchance: 'price_1JA9scGUWW5PzyCJ0fbkHKGI',
        coupounId: '3rxQe7xe',
      },
      gbp: {
        priceId: 'price_1JA9tEGUWW5PzyCJa2v1L0TL',
        priceIdLastchance: 'price_1JA9t4GUWW5PzyCJSieFTPsT',
        coupounId: 'hYBpVst5',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
      ron: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    et: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    de: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    es: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
    el: {
      eur: {
        priceId: 'price_1JA9sDGUWW5PzyCJO8LVjDD5',
        priceIdLastchance: 'price_1JA9ruGUWW5PzyCJztD8lfDf',
        coupounId: 'SBfprTiu',
      },
    },
  },
  mailigenModerate: {
    en: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
      usd: {
        priceId: 'price_1JFF1eGUWW5PzyCJ3BT35xFx',
        priceIdLastchance: 'price_1JFF1HGUWW5PzyCJulIW0vlF',
        coupounId: 'SO4tb5tg',
      },
      gbp: {
        priceId: 'price_1JFF2kGUWW5PzyCJinoMJpQ2',
        priceIdLastchance: 'price_1JFF2UGUWW5PzyCJJWE18ccU',
        coupounId: 'yQOlRBnu',
      },
    },
    ro: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
      ron: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    lv: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    lt: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    et: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    de: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    fr: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    es: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    hr: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    sk: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    ru: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    hu: {
      huf: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
    el: {
      eur: {
        priceId: 'price_1JFF0yGUWW5PzyCJlE2we6J5',
        priceIdLastchance: 'price_1JFF0cGUWW5PzyCJI3trZBWl',
        coupounId: '2GoUwDCS',
      },
    },
  },
}
