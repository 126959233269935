import { loadStripe, Stripe } from '@stripe/stripe-js'
import {
  EXPENSIVE_OFFER_CATEGORIES,
  MailigenPrice,
  ValidParams,
} from './types/offer'
import { getLastChanceFromSessionStorage } from './timer'
import { StripePriceIds, StripePrices } from './types/stripe'
import { DEV_STRIPE_PRICES, PROD_STRIPE_PRICES } from './stripePrices'

export const initializeStripe = (): Promise<Stripe> =>
  loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

export const isDevelopment = (): boolean =>
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'

const getStripeCheckoutData = (
  locale: string,
  category: string,
  currency: string,
  mailigenCheap: number
): StripePriceIds => {
  const stripePrices: StripePrices = isDevelopment()
    ? DEV_STRIPE_PRICES
    : PROD_STRIPE_PRICES

  if (mailigenCheap) {
    const stripeMailigenCheapPrices =
      mailigenCheap === MailigenPrice.cheap
        ? stripePrices.mailigenCheap
        : stripePrices.mailigenModerate
    const { priceId, priceIdLastchance, coupounId }: StripePriceIds =
      stripeMailigenCheapPrices[locale][currency]
        ? stripeMailigenCheapPrices[locale][currency]
        : stripeMailigenCheapPrices[locale]['eur']

    return { priceId, priceIdLastchance, coupounId }
  }

  if (EXPENSIVE_OFFER_CATEGORIES.includes(category)) {
    const expensiveStripePrices = stripePrices.expensive
    const { priceId, priceIdLastchance, coupounId }: StripePriceIds =
      expensiveStripePrices[locale][currency]
        ? expensiveStripePrices[locale][currency]
        : expensiveStripePrices[locale]['eur']

    return { priceId, priceIdLastchance, coupounId }
  }

  const cheapStripePrices = stripePrices.cheap
  const { priceId, priceIdLastchance, coupounId }: StripePriceIds =
    cheapStripePrices[locale][currency]
      ? cheapStripePrices[locale][currency]
      : cheapStripePrices[locale]['eur']

  return { priceId, priceIdLastchance, coupounId }
}

export const setStripeSessionIdToLocalStorage = (sessionId: string): void =>
  localStorage.setItem('session_id', sessionId)

export const getStripeSessionIdFromLocalStorage = (): string =>
  localStorage.getItem('session_id')

export const setGaPaymentTriggeredToLocalStorage = (): void =>
  localStorage.setItem('ga_payment_triggered', 'true')

export const getGaPaymentTriggeredFromLocalStorage = (): string =>
  localStorage.getItem('ga_payment_triggered')

export const isClientSide = (): boolean => typeof window !== 'undefined'

export const getCheckoutSessionId = async (
  locale: string,
  userData: ValidParams,
  originName: string,
  domainName: string,
  mailigenCheap?: number
): Promise<any | null> => {
  const lastChance = getLastChanceFromSessionStorage()
  const { userCategory, currency } = userData
  const { priceId, priceIdLastchance, coupounId } = getStripeCheckoutData(
    locale,
    userCategory,
    currency,
    mailigenCheap
  )

  const response = await fetch('/api/checkout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lastchanceFlag: lastChance,
      priceData: {
        priceId,
        priceIdLastchance,
        coupounId,
      },
      redirectPath: window.location.origin,
      locale: locale,
      userData: userData,
      originName: originName,
      domainName: domainName,
      mailigenCheap: mailigenCheap,
    }),
  })

  if (!response.ok) {
    return null
  }

  const {
    data: { id },
  } = await response.json()

  return id
}

export const getStripePaymentData = async (sessionId: string): Promise<any> => {
  const response = await fetch('/api/payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      session_id: sessionId,
    }),
  })

  if (!response.ok) {
    return false
  }

  const { paymentData } = await response.json()

  return paymentData
}

export const getPriceInEur = (
  priceAmount: number,
  currency: string
): number => {
  const getPrice = () => {
    switch (currency) {
      case 'ron':
        return priceAmount * 0.2
      case 'huf':
        return priceAmount * 0.0029
      case 'usd':
        return priceAmount * 0.82
      case 'gbp':
        return priceAmount * 1.16
      default:
        return priceAmount
    }
  }

  return Math.round(getPrice() * 100) / 100
}
