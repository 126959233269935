import { useRouter } from 'next/router'
import { returnToApplication, useAppRequiredQueryParams } from '../utils/offer'
import { isClientSide } from '../utils/stripe'

const EnsureRequiredParams: React.FC = ({ children }) => {
  const router = useRouter()
  const hasRequiredParams = useAppRequiredQueryParams()

  if (!hasRequiredParams) {
    if (isClientSide()) {
      returnToApplication(router)
    }
    return null
  }

  return <>{children}</>
}

export default EnsureRequiredParams
