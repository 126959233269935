import { isClientSide } from './stripe'

export const setTimerInSessionStorage = (timerValue: number): void => {
  sessionStorage.setItem('countdown', timerValue.toString())
}

export const setLastChanceInSessionStorage = (timerValue: number): void => {
  if (
    timerValue < 1 &&
    sessionStorage.getItem('lastchance') &&
    sessionStorage.getItem('lastchance') === 'true'
  ) {
    sessionStorage.setItem('lastchance', 'false')
    return
  }
  if (timerValue > 0 && !sessionStorage.getItem('lastchance')) {
    sessionStorage.setItem('lastchance', 'true')
  }
  return
}

export const getLastChanceFromSessionStorage = (): string =>
  sessionStorage.getItem('lastchance')

const DEFAULT_TIMER_STARTING_VALUE = 300

export const getTimerStartingValue = (): number => {
  if (!isClientSide()) {
    return 0
  }
  if (sessionStorage.getItem('countdown')) {
    return parseInt(sessionStorage.getItem('countdown'))
  }

  return DEFAULT_TIMER_STARTING_VALUE
}
